import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Elements
import ParseContent from 'components/shared/ParseContent'

const StyledAccordion = styled.section`
  position: relative;
`

const AccordionBackgroundLeft = styled.div`
  background-color: ${(props) => props.theme.color.face.background};
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 1;

  @media (min-width: 768px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`

const AccordionBackground = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 1;
`

const AccordionBackgroundRight = styled.div`
  background-color: ${(props) => props.theme.color.face.background};
  height: 100%;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
`

const AccordionContainer = styled.div`
  position: relative;
  z-index: 2;
`

const AccordionTitle = styled.h3`
  color: ${(props) => props.theme.color.text.purple};
  border-bottom: 1px solid ${(props) => props.theme.color.face.border};

  & svg {
    width: 20px;
    height: 20px;

    & path {
      fill: ${(props) => props.theme.color.text.purple};
    }
  }

  &:last-child {
    border-bottom: none;
  }

  @media (min-width: 992px) {
    font-size: ${(props) => props.theme.font.size.xm};
  }

  @media (max-width: 991px) {
    font-size: ${(props) => props.theme.font.size.m};
  }
`

const AccordionContent = styled(motion.div)`
  color: ${({ theme }) => theme.color.text.purple};
  overflow: hidden;
`

const AccordionImageContent = styled.div`
  & blockquote {
    color: ${(props) => props.theme.color.text.contrast};
  }

  @media (min-width: 992px) {
    & blockquote h3 {
      font-size: ${({ theme }) => theme.font.size.l};
      line-height: ${({ theme }) => theme.font.size.xl};
    }

    background-color: ${({ theme }) => theme.color.face.light};
    box-shadow: 0 0 25px rgb(0 0 0 / 10%);
    border-radius: 25px;
  }
`

interface AccordionProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Accordion
}

const Accordion: React.FC<AccordionProps> = ({ fields }) => {
  const [currentIndex, setCurrentIndex] = useState<number | null>(0)

  return (
    <StyledAccordion className="my-1 my-lg-5">
      <AccordionBackgroundLeft />
      <AccordionBackground>
        <div className="row h-100 d-md-flex d-none">
          <div className="col-12 col-md-11 col-xl-7 h-100">
            <AccordionBackgroundRight />
          </div>
        </div>
      </AccordionBackground>
      <AccordionContainer className="container py-5">
        <div className="row">
          <div className="col-lg-7 pr-lg-5">
            <ParseContent content={fields.description} />
            <div className="mt-4 mt-lg-5 pr-md-5 mr-lg-5">
              {fields?.list?.map((edge, index: number) => {
                const isOpen: boolean = index === currentIndex

                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index}>
                    <button
                      type="button"
                      className="px-0 w-100 text-left"
                      onClick={() => {
                        if (index === currentIndex) {
                          setCurrentIndex(null)
                        } else {
                          setCurrentIndex(index)
                        }
                      }}
                    >
                      <AccordionTitle className="py-3 d-flex align-items-center justify-content-between">
                        {edge?.title}
                        <motion.div
                          animate={isOpen ? 'open' : 'closed'}
                          variants={{
                            open: { rotate: 180 },
                            closed: { rotate: 0 },
                          }}
                        >
                          <svg
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M505.183,123.179c-9.087-9.087-23.824-9.089-32.912,0.002l-216.266,216.27L39.729,123.179
	c-9.087-9.087-23.824-9.089-32.912,0.002c-9.089,9.089-9.089,23.824,0,32.912L239.55,388.82c4.364,4.364,10.283,6.816,16.455,6.816
	c6.172,0,12.092-2.453,16.455-6.817l232.721-232.727C514.272,147.004,514.272,132.268,505.183,123.179z"
                            />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                          </svg>
                        </motion.div>
                      </AccordionTitle>
                    </button>
                    <AccordionContent
                      animate={isOpen ? 'open' : 'closed'}
                      variants={{
                        open: { height: 'auto' },
                        closed: { height: 0 },
                      }}
                    >
                      <ParseContent
                        className="pb-4"
                        content={edge?.description}
                      />
                    </AccordionContent>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="col-lg-5 mt-5 mt-lg-0 d-flex align-items-center justify-content-lg-start justify-content-center">
            {/* <AccordionImageContent className="d-flex align-items-center justify-content-center">
              <ParseContent className="p-3 p-md-5" content={fields.quote} />
            </AccordionImageContent> */}
          </div>
        </div>
      </AccordionContainer>
    </StyledAccordion>
  )
}

export default Accordion
